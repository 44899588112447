import SideBarComponent from "../../components/SideBarComponent"
import PortfolioSummaryComponent from "../../components/investments/PortfolioSummaryComponent"

import Constants from "../../constants/Constants";
import NewInvestment from "../../components/modals/NewInvestment";
import { useState } from "react";
import MobileNavBarComponent from "../../components/navbar/MobileNavBarComponent";
import NavBackComponent from "../../components/misc/NavBackComponent";



let AssetFinanceSummary = () => {
    let [isNewInvestmentModalActive, setIsNewInvestmentModalActive] = useState(false);
    let handleInvestModal = () => {
        setIsNewInvestmentModalActive(true);
        console.log("xlixxxx");
    }
    return <>
        <div className="flex w-full h-full mb-20">
            <SideBarComponent theActive={"invest"}></SideBarComponent>
            <div className="px-10 mt-10 flex-grow">
                <div className="mb-4">
                    <NavBackComponent title={"Large Asset Finance Portfolio"}></NavBackComponent>
                </div>
                <PortfolioSummaryComponent riskLevel={"low"}
                    portfolioSize={"140m"}
                    insurancePartner={"ARM Trusteeship"}
                    pricePerUnit={500000}
                    name={"Large Asset Finance"}
                    portfolioDescription={"finances equipment purchase for the businesses and the equipment purchase is used as collateral for the loan."}
                    interestRate={20}
                    onClick={handleInvestModal}
                    numberOfUnits={1000000}></PortfolioSummaryComponent>
            </div>
            {isNewInvestmentModalActive ? <NewInvestment focus={true} portfolio={Constants.PORTFOLIOS[5]} setIsNewInvestmentModalActive={setIsNewInvestmentModalActive}></NewInvestment> : ""}
        </div >
        <MobileNavBarComponent></MobileNavBarComponent>
    </>
}

export default AssetFinanceSummary;